<template>
  <div v-if="contact" @click="$emit('open', contact.user.id)" class="d-flex px-2 py-3 search">
    <div class="d-flex justify-space-between">
      <div>
        <StatusAvatar
          :userId="contact.user.id"
          :size="30"
          showStatus
          class="mr-3 mt-2"
        ></StatusAvatar>
      </div>
      <div class="d-flex flex-column">
        <h5 v-if="contact.user.fullName">{{ contact.user.fullName | trimText(22) }}</h5>
        <span v-if="contact.user.company" style="font-size: 10px">{{ contact.user.company.name | trimText(22) }}</span>
        <span v-if="user && user.showChatDescription" class="d-inline-block text-truncate company">{{ user.chatDescription | trimText(30) }}</span>
        <v-spacer></v-spacer>
        <span v-if="contact.conversation" style="font-size: 10px">
          {{ contact.conversation.threadType | getDescription($store.getters.enums.conversationThreadType) }}
        </span>
        <span v-if="contact.conversation" :class="{ 'font-italic': contact.conversation.isLastMessageDeleted }" class="message" style="font-size: 9px">
          {{ contact.conversation.isLastMessageDeleted ? '[' + $t('chatWindow.messageDeleted') + ']' : contact.conversation.lastMessage | trimText(20) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import StatusAvatar from '@/components/StatusAvatar.vue'
export default {
  name: 'SearchItem',
  components: { StatusAvatar },
  props: ['contact'],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    user () {
      return this.$store.getters['users/users'].find((el) => el.id === this.contact.user?.id)
    }
  },
  methods: {}
}
</script>
<style>
.search {
  border-top: solid 0.1px rgba(52, 58, 61, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.company {
  font-size: 9px;
}
.search:hover {
  color: white;
  background-color: #2e5940;
}
.search:hover .company {
  color: white;
}
</style>
