<template>
  <div>
    <Title :loading="loading">{{ $t("chatSidebar.search") }}</Title>
    <div class="chatWrapp">
      <div v-for="(item, i) in conversations" :key="i">
        <SearchItem
          :contact="item"
          @open="startConversationWithUser"
        ></SearchItem>
      </div>
      <div class="d-flex justify-center py-2">
        <v-btn
          v-if="conversations.length < totalCount"
          color="primary"
          @click="getSearch"
          :loading="loading"
          :disabled="loading"
          depressed
          >{{ $t("chatSidebar.more") }}...</v-btn
        >
      </div>
      <div v-if="conversations.length === 0 && !loading" class="d-flex justify-center px-2">
        {{ $t("chatSidebar.empty") }}
      </div>
    </div>
  </div>
</template>
<script>
import Service from '@/services'
import Title from '../Shared/Title.vue'
import SearchItem from '../Shared/SearchItem.vue'
import { startConversation } from '@/signalr/chat'
export default {
  name: 'Search',
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  components: { Title, SearchItem },
  data () {
    return {
      loading: false,
      conversations: [],
      totalCount: 0
    }
  },
  computed: {},
  watch: {
    search () {
      this.conversations = []
      this.totalCount = 0
      if (this.search && this.search.length > 0) {
        this.getSearch()
      }
    }
  },
  async created () {
    if (this.search && this.search.length > 0) await this.getSearch()
  },
  methods: {
    async getSearch () {
      this.loading = true

      try {
        const { data } = await Service.get('/Chat/Search', {
          skipCount: this.conversations.length,
          search: this.search
        })
        this.totalCount = data.totalCount
        if (data.totalCount === 0) {
          this.conversations = []
        } else {
          data.results.forEach((item) => this.conversations.push(item))
        }
        // this.conversations = data.results
      } catch (e) {}

      this.loading = false
    },
    startConversationWithUser (userId) {
      startConversation(userId)
    }
  }
}
</script>
